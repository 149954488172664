import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../component/layout/layout';
import { Link } from 'gatsby';
import React from 'react';
import SEO from '../component/seo';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ActionContext, fireEvent } from '../cra';

function NotFoundPage() {
  const { trackCurrentPage } = React.useContext(ActionContext);

  React.useEffect(() => {
    trackCurrentPage('404 page');
  }, []);
  return (
    <Layout>
      <SEO title='404: Not found' />
      <div className='error-page'>
        <div className='error-page-inner'>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <h1>404</h1>
          <h2>Page not Found</h2>
          <Link to='/ms'>
            <Button
              variant='btn btn-danger-custom'
              onClick={(e) => {
                fireEvent('navigate', e, {
                  description: 'navigate back to /ms from 404',
                });
              }}
            >
              <span>Back</span>
            </Button>
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
